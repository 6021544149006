import { Component, OnInit } from "@angular/core";
import { Platform, AlertController, MenuController } from "@ionic/angular";
import { Router } from "@angular/router";
import { CommonService } from "./service/common.service";
import { WebsocketService } from "./service/websocket.service";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent implements OnInit {
  constructor(
    private router: Router,
    public websocketService: WebsocketService,
    private menuController: MenuController,
    private route: Router,
    private commonservice: CommonService,
    private alertController: AlertController,
    private commonService: CommonService
  ) {}
  username;
  loginData;
  menuBuilder: any = [];
  menus = [
    {
      name: "Active Ports",
      path: "/active-reports",
    },
    {
      name: "Validation",
      path: "/validation",
    },

    {
      name: "Logout",
      path: "/login",
    },
  ];
  async logout() {
    const alert = await this.alertController.create({
      header: "Log out",
      backdropDismiss: false,
      message: "Are you sure you want to logout?",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          handler: (data) => {},
        },
        {
          text: "Ok",
          handler: (data) => {
            if (this.websocketService.isAlive()) {
              this.websocketService.disConnectSocket();
            }
            this.route.navigateByUrl("login");
          },
        },
      ],
    });
    await alert.present();
  }
  navigate(path) {
    if (path == "/login") {
      this.logout();
    } else if (path == "/active-reports") {
      if (this.router.url == path)
        this.router
          .navigateByUrl("/", { skipLocationChange: true })
          .then(() => {
            this.router.navigate(["/active-reports"]);
          });
    } else if (path == "/validation") {
      if (this.router.url == path)
        this.router
          .navigateByUrl("/", { skipLocationChange: true })
          .then(() => {
            this.router.navigate(["/validation"]);
          });
    } else {
      this.router.navigate([path]);
    }
  }
  ngOnInit(): void {
    this.route.navigateByUrl("login");
    this.commonService.companyInfo.subscribe((res) => {
      this.username = sessionStorage.getItem("userName");
      this.menuBuilder = [];
      this.loginData = JSON.parse(sessionStorage.getItem("loginData"));
      if (this.loginData && this.loginData.mainmenu) {
        let menu = this.loginData.mainmenu;
        this.menus.map((element, i) => {
          let a = element.name;
          if (menu.includes(a)) {
            this.menuBuilder.push(element);
          }
        });
      }
    });
  }
}
