// This file can be replaced during build by using the fileReplacements array.
// ng build --prod replaces environment.ts with environment.prod.ts.
// The list of file replacements can be found in angular.json.
let url = "";
let websocketurl = "";
// let appName = "live";
let appName = "live";
let s3ServerName = "";

switch (appName) {
  case "localHost":
    url = "http://localhost:8088/remote";
    break;
  case "live":
    url = "https://gpsvalidator.apmkingstrack.com/remote";
    websocketurl = "wss://gpsvalidator.apmkingstrack.com/remote";
    break;
}
export const ServerUrl = {
  live: url,
  websocketlive: websocketurl,
  s3ServerName: s3ServerName,
};
export const environment = {
  production: false,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as zone.run, zoneDelegate.invokeTask.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
