import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { AuthGuardService } from "./service/auth";

const routes: Routes = [
  {
    path: "",
    redirectTo: "add-button",
    pathMatch: "full",
  },
  {
    path: "active-reports",
    loadChildren:
      "./active-reports/active-reports.module#ActiveReportsPageModule",
    canActivate: [AuthGuardService],
  },
  {
    path: "add-button",
    loadChildren: "./add-button/add-button.module#AddButtonPageModule",
  },

  {
    path: "login",
    loadChildren: "./login/login.module#LoginPageModule",
    // canActivate: [AuthGuardService],
  },  { path: 'validation', loadChildren: './validation/validation.module#ValidationPageModule' },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      onSameUrlNavigation: "reload",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
