import { Injectable } from "@angular/core";
import { webSocket } from "rxjs/webSocket";
import { websocket } from "../interface/webSocket";
import { Observable, Subscription } from "rxjs";
import { QueueingSubject } from "queueing-subject";
import { ServerUrl } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class WebsocketService {
  constructor(private webSocketInstance: websocket) {}
  messagesSubscription: Subscription;
  socket$: any;
  subject$: any;
  input$ = new QueueingSubject<string>();
  messages$: Observable<any>;

  setProductService(ws: websocket) {
    this.webSocketInstance = ws;
  }
  isAlive() {
    let status = false;
    if (this.subject$ && this.subject$._socket) {
      this.subject$.closed == false ? (status = true) : (status = false);
    }
    return status;
  }
  connectSocket(input: any, type?: string) {
    if (type === "Dashboard") {
      this.subject$ = webSocket(ServerUrl.websocketlive + "/live/dashboard");
      this.subject$.next(input);
      console.log("Dashboard websocket connected!");
      this.subject$.subscribe(
        (res) => {
          if (this.webSocketInstance) {
            this.webSocketInstance.wsResponse(res);
          } else {
            this.subject$.unsubscribe();
            console.log("Websocket closed");
          }
        },
        (err) => {
          if (this.webSocketInstance) {
            this.webSocketInstance.wsOnError();
          }
        },
        (close) => {
          console.log("Websocket closed");
          if (localStorage.dashboardWebSocketData)
            this.connectSocket(JSON.parse(localStorage.dashboardWebSocketData));
        }
      );
    } else {
      this.subject$ = webSocket(ServerUrl.websocketlive + "/live/tcp");
      this.subject$.next(input);
      console.log("Admin websocket connected!");
      this.subject$.subscribe(
        (res) => {
          if (this.webSocketInstance) {
            this.webSocketInstance.wsResponse(res);
          } else {
            this.subject$.unsubscribe();
            console.log("Websocket closed");
          }
        },
        (err) => {
          if (this.webSocketInstance) {
            this.webSocketInstance.wsOnError();
          }
        },
        (close) => {
          console.log("Websocket closed");
          if (localStorage.dashboardWebSocketData)
            this.connectSocket(JSON.parse(localStorage.dashboardWebSocketData));
        }
      );
    }
  }
  disConnectSocket() {
    this.subject$.unsubscribe();
  }
  ConnectSocketMessagesend(input: any) {
    this.subject$.next(input);
  }
}
